// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bouncing-dot {
  display: inline-block;
  animation-name: bouncing-dot-bounce;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.bouncing-dot:nth-child(2) {
  animation-delay: 125ms;
}

.bouncing-dot:nth-child(3) {
  animation-delay: 250ms;
}

@keyframes bouncing-dot-bounce {
  0% {
    transform: none;
  }

  33% {
    transform: translateY(-0.2em);
  }

  66% {
    transform: none;
  }
}
`, "",{"version":3,"sources":["webpack://./app/theme/bouncing-dot.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mCAAmC;EACnC,yBAAyB;EACzB,mCAAmC;EACnC,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".bouncing-dot {\n  display: inline-block;\n  animation-name: bouncing-dot-bounce;\n  animation-duration: 700ms;\n  animation-iteration-count: infinite;\n  animation-timing-function: ease-in-out;\n}\n\n.bouncing-dot:nth-child(2) {\n  animation-delay: 125ms;\n}\n\n.bouncing-dot:nth-child(3) {\n  animation-delay: 250ms;\n}\n\n@keyframes bouncing-dot-bounce {\n  0% {\n    transform: none;\n  }\n\n  33% {\n    transform: translateY(-0.2em);\n  }\n\n  66% {\n    transform: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
