// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-handle-enter {
  animation-timing-function: ease-in-out;
  animation-name: sidebar-handle-emphasize;
  animation-duration: 400ms;
  animation-delay: -50ms;
}

@keyframes sidebar-handle-emphasize {
  0% {
    transform: translateX(-200%);
  }
  20% {
    transform: translateX(0.5rem);
    animation-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }
  100% {
    transform: translateX(0);
  }
}

.sidebar-handle-exit {
  @apply -translate-x-[200%] scale-y-0 transition-all;
}
`, "",{"version":3,"sources":["webpack://./app/theme/sidebar.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,wCAAwC;EACxC,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,6BAA6B;IAC7B,8DAA8D;EAChE;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":[".sidebar-handle-enter {\n  animation-timing-function: ease-in-out;\n  animation-name: sidebar-handle-emphasize;\n  animation-duration: 400ms;\n  animation-delay: -50ms;\n}\n\n@keyframes sidebar-handle-emphasize {\n  0% {\n    transform: translateX(-200%);\n  }\n  20% {\n    transform: translateX(0.5rem);\n    animation-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n\n.sidebar-handle-exit {\n  @apply -translate-x-[200%] scale-y-0 transition-all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
