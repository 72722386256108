// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./github.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.codehilite tr td {
  border: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.428571429;
}

table.codehilite tr td.line-number {
  width: 1%;
}

table.codehilite tr td.line-number::before {
  content: attr(data-line-number);
  padding-right: 20px;
}

table.codehilite pre {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  word-break: break-all;
  word-wrap: break-word;
}

table.codehilite.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-spacing: 0;
}

div.highlight:not(code .highlight) {
  overflow: scroll;
}
`, "",{"version":3,"sources":["webpack://./app/theme/syntax-highlighting.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,SAAS;EACT,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@import './github.css';\n\ntable.codehilite tr td {\n  border: 0;\n  padding: 0;\n  font-size: 13px;\n  line-height: 1.428571429;\n}\n\ntable.codehilite tr td.line-number {\n  width: 1%;\n}\n\ntable.codehilite tr td.line-number::before {\n  content: attr(data-line-number);\n  padding-right: 20px;\n}\n\ntable.codehilite pre {\n  background-color: transparent;\n  border: 0;\n  margin: 0;\n  padding: 0;\n  word-break: break-all;\n  word-wrap: break-word;\n}\n\ntable.codehilite.table {\n  width: 100%;\n  max-width: 100%;\n  margin-bottom: 20px;\n  border-collapse: collapse;\n  border-spacing: 0;\n}\n\ndiv.highlight:not(code .highlight) {\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
